<template>
	<div class="header-search">
		<i class="iconfont iconsearch" @click.stop="click"></i>
		<input
			ref="searchInput"
			:class="{ show: show }"
			v-model="searchValue"
			type="text"
			@click.stop=""
			@keyup.enter="search"
		/>
		<div v-if="show" class="search-block">
			<div
				class="search-history"
				v-if="localSearchHistory && localSearchHistory.length > 0"
			>
				<div class="title">
					<h2>搜索历史</h2>
					<el-link type="info" :underline="false" @click="clearLocalSearch"
						>清空</el-link
					>
				</div>
				<div class="content">
					<el-tag
						v-for="(item, index) in localSearchHistory"
						:key="index"
						size="mini"
						closable
						type="info"
						@close="handleDeleteHistory(index)"
						@click="handleSearch(item)"
						>{{ item }}</el-tag
					>
				</div>
			</div>
			<div class="search-hot">
				<div class="title">
					<h2>站点热搜</h2>
				</div>
				<ul class="content">
					<li
						v-for="(item, index) in hotSearch"
						:key="item.id"
						class="item"
						@click="handleSearch(item.x)"
					>
						<span :class="index < 3 ? 'hot' : ''" class="order">{{
							index + 1
						}}</span>
						<p :class="index < 3 ? 'hot' : ''" class="item-p">
							{{ item.x }}
						</p>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import { addKeyword, fetchKeywordTopTen } from "@/api/keyword";
import Mint from "mint-filter";
import mintJson from "@/assets/json/sensitive-words.json";
import _ from "lodash";

export default {
	name: "header-search",
	data() {
		return {
			searchValue: "",
			show: false,
			localSearchHistory: [],
			hotSearch: [],
			mint: null,
			mintKeywords: [],
			status: true,
		};
	},
	created() {
		this.getSearchHistoryFromLocal();
		this.getKeywordTopTen();
		this.mint = new Mint([]);
	},
	mounted() {
		this.$refs.searchInput.addEventListener(
			"input",
			_.debounce(this.handleInput, 150)
		);
	},
	watch: {
		show(value) {
			if (value) {
				document.body.addEventListener("click", this.close);
			} else {
				document.body.removeEventListener("click", this.close);
			}
		},
	},
	methods: {
		search() {
			if (this.searchValue) {
				if (this.status) {
					this.$router.push({
						name: "search",
						params: { words: this.searchValue },
					});
					this.handleAddKeyword();
					this.close();
				} else {
					this.$message.warning(`搜索关键词涉及敏感词汇，请重新输入！`);
				}
			} else {
				this.$message.warning(`请输入搜索关键词！`);
			}
		},
		click() {
			this.searchValue = "";
			this.show = !this.show;
			if (this.show) {
				this.$refs.searchInput && this.$refs.searchInput.focus();
			}
		},
		close() {
			this.$refs.searchInput && this.$refs.searchInput.blur();
			this.show = false;
		},
		// 新增关键词搜索
		async handleAddKeyword() {
			try {
				await addKeyword({
					keyword: this.searchValue,
				});
				this.getSearchHistoryFromLocal();
				const index = this.localSearchHistory.findIndex(
					(value, index, array) => {
						return value === this.searchValue;
					}
				);
				if (index < 0) {
					if (this.localSearchHistory.length < 10) {
						this.localSearchHistory.unshift(this.searchValue);
					} else {
						this.localSearchHistory.splice(
							this.localSearchHistory.length - 1,
							1
						);
						this.localSearchHistory.unshift(this.searchValue);
					}
				} else {
					this.localSearchHistory.splice(index, 1);
					this.localSearchHistory.unshift(this.searchValue);
				}
				const lshStr = JSON.stringify(this.localSearchHistory);
				localStorage.setItem("search-history", lshStr);
			} catch (error) {
				this.$message.error(error);
			}
		},
		// 获取本地缓存搜索历史
		getSearchHistoryFromLocal() {
			const searchHistoryValue = localStorage.getItem("search-history");
			this.localSearchHistory = searchHistoryValue
				? JSON.parse(searchHistoryValue)
				: [];
		},
		// 清除本地缓存搜索历史
		clearLocalSearch() {
			localStorage.removeItem("search-history");
			this.localSearchHistory = [];
		},
		// 删除搜索历史
		handleDeleteHistory(index) {
			this.localSearchHistory.splice(index, 1);
			localStorage.removeItem("search-history");
			localStorage.setItem(
				"search-history",
				JSON.stringify(this.localSearchHistory)
			);
		},
		//获取关键词搜索前10
		async getKeywordTopTen() {
			try {
				const res = await fetchKeywordTopTen();
				if (res.code === 200) {
					this.hotSearch = res.data || [];
				}
			} catch (error) {
				this.$message.error(error);
			}
		},
		handleSearch(value) {
			this.searchValue = value;
			this.search();
		},
		handleInput() {
			const index = mintJson.findIndex((value, index, array) => {
				return value.SENSITIVEWORDS === this.searchValue;
			});
			if (index >= 0) {
				this.mint.add(this.searchValue);
			}
			this.status = this.mint.verify(this.searchValue);
			this.searchValue = this.mint.filter(this.searchValue).text;
		},
	},
};
</script>

<style scoped lang="less">
.header-search {
	display: inline-block;
	position: relative;

	i {
		font-size: 18px;
		position: relative;
		top: 3px;
	}

	input {
		border: none;
		outline: none;
		overflow: hidden;
		background: transparent;
		height: 30px;
		width: 0;
		transition: 0.2s all;

		&.show {
			width: 300px;
			margin-left: 10px;
		}

		&:focus {
			border-bottom: 1px solid #8fd0cc;
		}
	}
	.search-block {
		width: 329px;
		height: fit-content;
		background-color: #fff;
		border-radius: 12px;
		box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
			rgba(17, 17, 26, 0.05) 0px 8px 32px;
		box-sizing: border-box;
		padding: 12px;
		position: absolute;
		z-index: 9999;

		.search-history {
			.title {
				display: flex;
				justify-content: space-between;
			}
			.content {
				display: flex;
				flex-wrap: wrap;
				max-height: 52px;
				overflow: hidden;
				.el-tag {
					margin: 3px 5px;
				}
			}
		}

		.search-hot {
			.title {
				display: flex;
				justify-content: space-between;
			}
			ul.content {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				li.item {
					width: 140px;
					height: 36px;
					display: flex;
					justify-content: flex-start;
					align-items: center;
					box-sizing: border-box;
					padding-left: 5px;
					.span,
					.item-p {
						height: 36px;
						line-height: 36px;
						font-size: 14px;
					}
					.item-p {
						margin-left: 12px;
						overflow: hidden; // 超出部分隐藏
						text-overflow: ellipsis; // 超出的文本显示省略号
						white-space: nowrap; // 强制不换行
					}
					.hot {
						color: #f85a54;
						font-weight: 600;
					}
				}

				li.item:hover {
					background-color: #e3e5e7;
					cursor: url("../assets/cursor/person.png"), pointer;
				}
			}
		}
	}
}
</style>
