import { registerMicroApps, start } from "qiankun";

registerMicroApps([
  {
    name: "vue2Admin",
    entry: "/child/vue2-admin/",
    container: "#app",
    activeRule: "/app/vue2Admin",
  },
  // {
  //   name: "reactApp",
  //   entry: "//localhost:3000",
  //   container: "#container",
  //   activeRule: "/app-react",
  // },

  // {
  //   name: "angularApp",
  //   entry: "//localhost:4200",
  //   container: "#container",
  //   activeRule: "/app-angular",
  // },
]);
// 启动 qiankun
start();

console.log("主应用", window.__POWERED_BY_QIANKUN__);
