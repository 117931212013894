<template>
	<div id="app" v-cloak>
		<layout-header></layout-header>
		<layout-body></layout-body>
		<layout-footer></layout-footer>
		<music-player></music-player>
		<vue-ins-progress-bar></vue-ins-progress-bar>
	</div>
</template>
<script>
import layoutHeader from "@/components/layout/layout-header";
import layoutBody from "@/components/layout/layout-body";
import layoutFooter from "@/components/layout/layout-footer";
import musicPlayer from "@/components/musicPlayer";
import { addVisitorLog } from "@/api/visitor";
import { publicIpv4 } from "public-ip";
const { UAParser } = require("ua-parser-js");

export default {
	name: "app",
	components: {
		layoutHeader,
		layoutBody,
		layoutFooter,
		musicPlayer,
	},
	watch: {
		"$route.path": {
			immediate: true,
			deep: true,
			handler(newVal) {
				this.appendVisitorLog(newVal);
			},
		},
	},
	created() {
		this.$insProgress.start();

		this.$router.beforeEach((to, from, next) => {
			this.$insProgress.start();
			next();
		});

		this.$router.afterEach((to, from) => {
			this.$insProgress.finish();
		});
	},
	mounted() {
		let lang = window.localStorage.getItem("lang");
		if (lang === "en_US") {
			this.$i18n.locale = "en";
		} else if (lang === "zh_CN") {
			this.$i18n.locale = "zh";
			this.locale = null;
		}
		this.$insProgress.finish();
	},
	methods: {
		async appendVisitorLog(path) {
			try {
				const clientIp = await publicIpv4();
				let { browser, os } = new UAParser().getResult();
				await addVisitorLog({
					ip: clientIp,
					browser: `${browser.name} ${browser.version}`,
					os: `${os.name} ${os.version}`,
					path,
				});
			} catch (error) {}
		},
	},
};
</script>
<style lang="less">
#app {
	font-size: 17px;
	color: #6f6f6f;
}
</style>