<template>
	<div class="aplayer"></div>
</template>

<script>
import { fetchMusic } from "@/api/music";
import { Base64 } from "js-base64";
import "aplayer/dist/APlayer.min.css";
import APlayer from "aplayer";

export default {
	data() {
		return {
			musicList: [],
			isShowPlayer: false,
			audio: {
				title: "不要说话",
				artist: "陈奕迅",
				src: "http://music.163.com/song/media/outer/url?id=25906124.mp3",
				pic: "http://p2.music.126.net/96L4ojVi9N3tdHk3r3GXjQ==/109951163066640079.jpg",
				lrc: "[00:00.00] 作词 : 小柯[00:01.00] 作曲 : 小柯[00:02.00] 编曲 : Mac Chew[00:18.77]深色的海面布满白色的月光[00:25.11]我出神望着海 心不知飞哪去[00:31.64]听到她在告诉你[00:35.28]说她真的喜欢你[00:39.48]我不知该 躲哪里[00:47.17]爱一个人是不是应该有默契[00:54.15]我以为你懂得每当我看着你[01:00.07]我藏起来的秘密[01:03.67]在每一天清晨里[01:07.60]暖成咖啡 安静的拿给你[01:14.33]愿意 用一支黑色的铅笔[01:18.81]画一出沉默舞台剧[01:22.95]灯光再亮 也抱住你[01:28.53]愿意 在角落唱沙哑的歌[01:33.06]再大声也都是给你[01:37.24]请用心听 不要说话[01:51.54]爱一个人是不是应该有默契[01:58.36]我以为你懂得每当我看着你[02:04.34]我藏起来的秘密[02:08.22]在每一天清晨里[02:11.47]暖成咖啡 安静的拿给你[02:18.49]愿意 用一支黑色的铅笔[02:22.92]画一出沉默舞台剧[02:27.31]灯光再亮 也抱住你[02:33.04]愿意 在角落唱沙哑的歌[02:37.33]再大声也都是给你[02:41.46]请用心听 不要说话[03:15.81]愿意 用一支黑色的铅笔[03:19.95]画一出沉默舞台剧[03:24.43]灯光再亮 也抱住你[03:29.82]愿意 在角落唱沙哑的歌[03:34.19]再大声也都是给你[03:38.48]请原谅我 不会说话[03:44.11]愿意 用一支黑色的铅笔[03:48.55]画一出沉默舞台剧[03:52.68]灯光再亮 也抱住你[03:58.35]愿意 在角落唱沙哑的歌[04:02.84]再大声也都是给你[04:06.97]爱是用心吗 不要说话",
			},
		};
	},
	created() {
		this.getMusic();
	},
	methods: {
		async getMusic() {
			try {
				this.musicList = [];
				const res = await fetchMusic();
				for (const music of res.data) {
					this.musicList.push({
						name: music["title"],
						artist: music.artist,
						url: music["url"],
						cover: music["pic"],
						lrc: music['lrc'],
					});
				}
				const ap = new APlayer({
          fixed: true,
					autoplay: true,
					loop: "all",
					order: "list",
					preload: "auto",
					volume: 0.7,
					mutex: true,
					listFolded: false,
					listMaxHeight: 90,
					lrcType: 1,
					audio: this.musicList,
				});
			} catch (error) {
				this.$message.error(error);
			}
		},
	},
};
</script>

<style lang="less" scoped>
</style>