export const weatherIcon = (desc) => {
	const enumData = {
		晴: "Clear",
		多云: "Cloudy",
		阴: "Overcast",
		阵雨: "Rain Showers",
		雷阵雨: "Thunder Storm",
		雷阵雨伴有冰雹: "Sleet Showers",
		雨夹雪: "Sleet",
		小雨: "LightRain",
		中雨: "Rain",
		大雨: "Heavy Rain",
		暴雨: "Light Drizzle Showers",
		大暴雨: "Drizzle Showers",
		特大暴雨: "Heavy Drizzle Showers",
		阵雪: "Snow Showers",
		小雪: "Light Snow",
		中雪: "Snow",
		大雪: "Heavy Snow",
		暴雪: "Heavy Snow Showers",
		雾: "Fog",
		冻雨: "Thunder Storm",
		沙城暴: "Overcast",
		小到中雨: "Rain",
		中到大雨: "Heavy Rain",
		大到暴雨: "Light Drizzle Showers",
		暴雨到大暴雨: "Drizzle Showers",
		大暴雨到特大暴雨: "Heavy Drizzle Showers",
		小到中雪: "Snow",
		中到大雪: "Heavy Snow",
		大到暴雪: "Heavy Snow Showers",
		浮尘: "Fog",
		扬沙: "Fog",
		强沙尘暴: "Overcast",
		浓雾: "Fog",
		龙卷风: "Overcast",
		弱高吹雪: "Snow",
		轻雾: "Fog",
		强浓雾: "Fog",
		霾: "Fog",
		中度霾: "Fog",
		重度霾: "Fog",
		严重霾: "Fog",
		大雾: "Fog",
		特强浓雾: "Fog",
		雨: "Rain",
		雪: "Snow",
	};
	return enumData[desc];
};
