import request from "@/utils/request";

/**
 * 获取歌曲列表（不分页）
 * @method get
 */
export function fetchMusic() {
	return request({
		url: "/song",
		method: "get",
	});
}
