import { nanoid } from "nanoid";

export const oauth = {
  gitee: {
    GITEE_CLIENT_ID:
      `64d0cb8fe89c1db81192711eec555924d4598b2d7a86a813bbeff71572b84a21`,
    REDIRECT_URI: `http://lewiis.top/`,
  },
  gitee_test: {
    GITEE_CLIENT_ID:
      `16b744f6521390adfe7653334b1f2227d40e047191a01852575454cf59444d19`,
    REDIRECT_URI: `http://localhost:5498/`,
  },
  github: {
    GITHUB_CLIENT_ID: `6f69dc8edcbaf3780541`,
    REDIRECT_URI: `http://lewiis.top/`,
    SCOPE: "user",
    ALLOW_SIGNUP: true,
    STATE: nanoid(),
  },
  huawei: {
    CLIENT_ID: `108042279`,
    REDIRECT_URI: `http://lewiis.top/`,
    STATE: nanoid(),
  },
  baidu: {
    ID: `32254957`,
    REDIRECT_URI: `http://lewiis.top/`,
    API_KEY: `jsAgEehIBMYxcTaa1rc6m56q`,
    STATE: nanoid(),
  },
  weibo: {
    CLIENT_ID: `1348332593`,
    REDIRECT_URI: `http://lewiis.top`,
  },
  osChina: {
    CLIENT_ID: `7hh3CbbeeGYaPzZ4KlGd`,
    REDIRECT_URI: `http://lewiis.top/`,
    STATE: nanoid(),
  },
  qq: {
    CLIENT_ID: `102071814`,
    REDIRECT_URI: `http://lewiis.top/qqcallback`,
  },
};

export const oauthOpenUrl = {
  gitee:
    `https://gitee.com/oauth/authorize?client_id=${oauth.gitee.GITEE_CLIENT_ID}&redirect_uri=${oauth.gitee.REDIRECT_URI}&response_type=code`,
  gitee_test:
    `https://gitee.com/oauth/authorize?client_id=${oauth.gitee_test.GITEE_CLIENT_ID}&redirect_uri=${oauth.gitee_test.REDIRECT_URI}&response_type=code`,
  github:
    `https://github.com/login/oauth/authorize?client_id=${oauth.github.GITHUB_CLIENT_ID}&redirect_uri=${oauth.github.REDIRECT_URI}&scope=${oauth.github.SCOPE}&state=${oauth.github.STATE}&allow_signup=${oauth.github.ALLOW_SIGNUP}`,
  huawei:
    `https://oauth-login.cloud.huawei.com/oauth2/v3/authorize?response_type=code&access_type=offline&state=${oauth.huawei.STATE}&client_id=${oauth.huawei.CLIENT_ID}&redirect_uri=${oauth.huawei.REDIRECT_URI}&scope=openid+profile`,
  baidu:
    `https://openapi.baidu.com/oauth/2.0/authorize?response_type=code&client_id=${oauth.baidu.API_KEY}&redirect_uri=${oauth.baidu.REDIRECT_URI}&state=${oauth.huawei.STATE}`,
  weibo:
    `https://api.weibo.com/oauth2/authorize?client_id=${oauth.weibo.CLIENT_ID}&response_type=code&redirect_uri=${oauth.weibo.REDIRECT_URI}`,
  osChina:
    `https://www.oschina.net/action/oauth2/authorize?response_type=code&client_id=${oauth.osChina.CLIENT_ID}&redirect_uri=${oauth.osChina.REDIRECT_URI}&state=${oauth.huawei.STATE}`,
  qq:
    `https://graph.qq.com/oauth2.0/show?which=Login&display=pc&client_id=${oauth.qq.CLIENT_ID}&response_type=token&scope=all&redirect_uri=${oauth.qq.REDIRECT_URI}`,
};

// 是否登录
export const calculateIsLogin = () => {
  const cui = sessionStorage.getItem("sqlUserInfo");
  const sqlUserInfo = cui ? JSON.parse(cui) : {};
  return Object.keys(sqlUserInfo).length > 0;
};

// 授权登录用户信息
export const getCurrentOauthUserInfo = () => {
  const cui = sessionStorage.getItem("sqlUserInfo");
  return cui ? JSON.parse(cui) : {};
};
